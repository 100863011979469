<template>
  <div class="home">
    <img src="./movie/bg.jpg" class="bg_img" />
    <el-container>
      <el-container>
        <el-header style="height: 70px" class="header-el">
          <div style="color: #000">{{ COMPANY_NAME_All }}</div>
          <el-menu :default-active="$route.path" mode="horizontal" class="el-menu-vertical-demo"
            background-color="transparent" active-text-color="#000" style="
              min-width: 1200px;
              white-space: nowrap;
              padding: 0px 0px 0px 0px;
            ">
            <template v-for="(item,index) in routes_data">
              <el-menu-item style="
                  text-align: center;
                  padding: 0 20px
                  border-bottom-color: rgb(255 255 255);
                " :index="item.path" v-if="item.meta.menu" @click="go(item.path)">
                <span slot="title">{{ item.meta.name }}</span>
              </el-menu-item>
            </template>
          </el-menu>
          <div style="width: 300px">
            <el-dropdown v-if="$store.state.login_token != ''">
              <!-- <el-button type="text" @click="goUserInfo">
                  个人中心
                </el-button> -->
              <div class="avatar_box">
                <el-image style="width: 40px; line-height: 40px; cursor: pointer" :src="$store.state.avatar"
                  @click="goUserInfo"></el-image>
                <img v-if="$store.state.vip" src="../assets/vip_1.svg" alt=""
                  style="width: 20px; height: 20px; color: #fff" class="isVip" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <!--  <el-dropdown-item @click.native="goDeposit"
                  >会员充值</el-dropdown-item
                > -->
                <el-dropdown-item @click.native="outLogin">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button @click="updata" style="margin: 0 20px" class="btn login_btn" v-if="$store.state.login_token != ''">
              上传视频
            </el-button>
            <el-button class="btn" type="text" style="margin: 0 20px" @click="
              dialogVisible = true;
            registerFlag = false;
            " v-if="$store.state.login_token == ''">注册</el-button>
            <el-button type="danger" class="btn login_btn" @click="
              dialogVisible = true;
            registerFlag = true;
            " v-if="$store.state.login_token == ''">登录</el-button>
          </div>
        </el-header>
        <el-scrollbar>
          <el-main class="wrap">
            <router-view></router-view>
          </el-main>
        </el-scrollbar>
        <el-footer class="footer_box" style="height: 125px;">
          <div style="padding: 5px 0">
            Copyright ©&emsp; 2022 www.tianqi.video All rights reserved {{ COMPANY_NAME_All }} @ 版权所有
          </div>
          <p>公司地址：{{ ADDRESS }} </p>
          <p style="padding: 5px 0">公司名称：{{ COMPANY_NAME_All }}</p>
          <p>ICP备案号：渝ICP备2021007722号-2 </p>
          <p style="padding: 5px 0 0 0;display:flex;justify-content: center;">
            <el-link style="color: #000; font-size: 12px" href="https://www.piyao.org.cn/yybgt/index.htm" target="_blank"
              :underline="false">网络谣言曝光台 &emsp;|&emsp;</el-link>
            <el-link style="color: #000; font-size: 12px" href="https://jbts.mct.gov.cn/" target="_blank"
              :underline="false">12138全国文化市场举报平台 &emsp;|&emsp;</el-link>
            <el-link style="color: #000; font-size: 12px" href="https://www.12377.cn/" target="_blank"
              :underline="false">中国互联网不良信息举报中心 &emsp;|&emsp;</el-link>
            <span>违法和不良信息举报：023-67463753&emsp;|&emsp;</span>
            <span>青少年守护专线：023-65438789&emsp;|&emsp;</span>
            <span>网络内容从业人员违法违规行为举报：xzb@nineton.cn</span>
          </p>
        </el-footer>
      </el-container>
    </el-container>

    <!-- 登录注册 -->
    <el-dialog :title="registerFlag ? '登录账号' : '注册账号'" :visible.sync="dialogVisible" width="600px" @closed="openInint"
      :close-on-click-modal="false">
      <el-form label-position="left" label-width="80px" :model="registerForm" ref="registerForm"
        style="width: 350px; margin: 0 auto" :rules="registerRules">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="registerForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="msg_code">
          <el-input v-model="registerForm.msg_code">
            <el-button slot="append" type="text" @click="sendcode" :disabled="!show">
              获取验证码
              <span v-show="!show" class="count">({{ count }}s)</span>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item v-if="!registerFlag" label="真实姓名" prop="realname">
          <el-input v-model="registerForm.realname"></el-input>
        </el-form-item>
        <el-form-item v-if="!registerFlag" label="身份证号" prop="id_card_no">
          <el-input v-model="registerForm.id_card_no"></el-input>
        </el-form-item>

        <el-button @click="Login" style="width: 350px">{{
          registerFlag ? "立即登录" : "立即注册"
        }}</el-button>
        <div style="display:flex;justify-content: space-between;">
          <div>
            同意<el-button type="text" @click="goAgreement">用户协议</el-button>及<el-button type="text"
              @click="goConceal">隐私政策</el-button>
          </div>
          <div>
            <el-button type="text" @click="goRegister">
              {{ registerFlag ? "没有账号？立即注册" : "已有账号立马登录" }}
            </el-button>
          </div>
        </div>
      </el-form>
      <el-dialog width="400px" title="请完成下列验证后继续" :visible.sync="innerVisible" append-to-body v-if="innerVisible"
        :close-on-click-modal="false">
        <slide-verify v-loading="slideLoading" element-loading-spinner="el-icon-loading" element-loading-text="正在验证"
          class="slide" :l="42" :r="10" :w="340" :h="165" @success="onSuccess" @fail="onFail" @refresh="onRefresh"
          slider-text="按住左边按钮拖动完成上方拼图"></slide-verify>
      </el-dialog>
    </el-dialog>

    <!-- 视频上传 -->
    <el-dialog title="视频上传" :visible.sync="videoFlag" width="800px" @closed="openInint2" :close-on-click-modal="false">
      <el-form label-position="left" label-width="120px" :model="videoForm" ref="videoForm"
        style="width: 500px; margin: 0 auto" :rules="videoFormRules">
        <el-form-item label="视频描述" prop="title">
          <el-input v-model="videoForm.title"></el-input>
        </el-form-item>
        <el-form-item label="上传视频封面" prop="cover">
          <el-upload class="avatar-uploader" :show-file-list="false" action :http-request="UploadImg"
            :on-success="handleSuccessImg" :on-error="handfileErrImg" :before-upload="beforeAvatarUploadImg"
            v-model="videoForm.cover">
            <img v-if="videoForm.cover" :src="videoForm.cover" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">上传图片大小不能超过30m</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传视频" prop="file">
          <el-upload class="upload-demo" v-model="videoForm.file" action="" :http-request="Upload"
            :on-preview="handlePreview" :before-remove="beforeRemove" :on-remove="handleRemove"
            :on-success="handleSuccess" :on-exceed="handleExceed" :on-error="handfileErr"
            :before-upload="beforeAvatarUpload" drag :limit="1" :file-list="fileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div slot="tip" class="el-upload__tip">上传文件大小不能超过 1G</div>
          </el-upload>
        </el-form-item>
        <div style="text-align: center">
          <el-button style="width: 350px" @click="Addvideo">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
const TIME_COUNT = 60;
import { client, getFileNameUUID, dateFormat } from "@/util/update";

export default {
  name: "Home",
  data() {
    return {
      COMPANY_NAME: process.env.VUE_APP_COMPANY_NAME,
      COMPANY_NAME_All: process.env.VUE_APP_COMPANY_NAME_All,
      ADDRESS: process.env.VUE_APP_ADDRESS,
      aside_width: 160,
      collapse: true,
      routes_data: [],
      dialogVisible: false,
      timer: null, //计时器
      code: "", //验证码
      count: "", //倒计时
      show: true, //控制按钮
      registerFlag: true,
      registerForm: {
        phone: "",
        msg_code: "",
        realname: "",
        id_card_no: "",
      },
      registerRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "change" },
          { min: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        msg_code: [
          { required: true, message: "请输入验证码", trigger: "change" },
        ],
        realname: [
          { required: true, message: "请输入真实姓名", trigger: "change" },
        ],
        id_card_no: [
          { required: true, message: "请输入身份证号", trigger: "change" },
        ],
      },
      videoFormRules: {
        title: [
          { required: true, message: "请输入视频描述", trigger: "change" },
        ],
        file: [
          { required: true, message: "请上传视频文件", trigger: "change" },
        ],
        cover: [
          { required: true, message: "请上传视频封面", trigger: "change" },
        ]
      },
      videoForm: {
        title: "",
        file: "",
        cover: ""
      },
      msg: "",
      innerVisible: false,
      slideLoading: false,
      videoFlag: false,
      fileList: [], //文件列
      showProgress: false, //进度条的显示
      dataObj: {}, //存签名信息
      progress: 0, //进度条数据
      fileInfo: {}, //文件详情
    };
  },

  created() {
    this.routes_data = this.$router.options.routes[0].children;
  },
  methods: {
    go(path) {
      if (this.$route.path == path) return;
      this.$router.push(path);
    },
    goAgreement() {
      this.registerForm = {
        phone: "",
        msg_code: "",
        realname: "",
        id_card_no: "",
      };
      this.dialogVisible = false;
      this.$router.push("/user_text");
    },
    goConceal() {
      this.registerForm = {
        phone: "",
        msg_code: "",
        realname: "",
        id_card_no: "",
      };
      this.dialogVisible = false;
      this.$router.push("/conceal");
    },
    openInint() {
      this.$refs.registerForm.resetFields();
    },
    openInint2() {
      this.$refs.videoForm.resetFields();
      this.fileList = [];
    },
    handAside() {
      this.collapse = !this.collapse;
    },
    // 发送验证码
    sendcode() {
      this.$refs.registerForm.validateField("phone", async (error) => {
        if (!error) {
          this.innerVisible = true;
          //请求
        } else {
          return false;
        }
      });
    },
    onSuccess() {
      // this.innerVisible = false
      this.slideLoading = true;
      setTimeout(async () => {
        setTimeout(() => {
          this.slideLoading = false;
          this.innerVisible = false;
        }, 2000);
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer); // 清除定时器
              this.timer = null;
            }
          }, 1000);
        }
        const [err, res] = await this.$http.post("web/tools/sendSMS", {
          phone: this.registerForm.phone,
        });
        if (err) {
          return;
        }
      }, 2000);
    },
    onFail() {
      this.msg = "";
    },
    onRefresh() {
      this.msg = "";
    },
    goRegister() {
      this.$refs.registerForm.resetFields();
      this.registerFlag = !this.registerFlag;
    },
    // 登录
    async Login() {
      this.$refs.registerForm.validate(async (v, i) => {
        if (v) {
          const { registerForm } = this;
          const [err, res] = await this.$http.post("web/login/login", {
            ...registerForm,
          });
          if (err) {
            return;
          }
          this.$store.commit("CHANGE_LOGIN_TOKEN", res.login_token);
          this.$store.commit("CHANGE_NICK_NAME", res.nickname);
          this.$store.commit("CHANGE_USER_ID", res.id);
          this.$store.commit("CHANGE_AVATAR", res.avatar);
          this.$store.commit("CHANGE_VIP", res.vip);
          this.dialogVisible = false;
          localStorage.setItem("stateInfo", JSON.stringify(this.$store.state));
          this.$message({
            message: "登陆成功",
            type: "success",
          });
        }
      });
    },
    // 退出登录
    async outLogin() {
      const [err, res] = await this.$http.post("web/user/logout", {
        id: this.$store.state.user_id,
        token: this.$store.state.login_token,
      });
      if (err) {
        this.$message.error("发生未知错误,请重新尝试");
        return;
      }
      /* this.$message({
        message: "已退出登录",
        type: "success",
      }); */
      this.$store.commit("CHANGE_LOGIN_TOKEN", "");
      this.$store.commit("CHANGE_NICK_NAME", "");
      this.$store.commit("CHANGE_USER_ID", "");
      this.$store.commit("CHANGE_AVATAR", "");
      this.$store.commit("CHANGE_VIP", "");
      localStorage.setItem("stateInfo", JSON.stringify(this.$store.state));
      window.location.reload();
    },
    // 去充值
    goDeposit() {
      this.$router.push("/deposit");
    },
    // 上传文件
    updata() {
      this.videoFlag = true;
      this.getOssData();
    },
    // 文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message.warning(`每次只能上传 1 个文件`);
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(file) { },
    // 删除文件之前的钩子
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 文件列表移除文件时的钩子
    async handleRemove(file, fileList) {
      this.videoForm.file = "";
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.fileInfo = res;
      this.videoForm.file = res.url;
      this.fileList = fileList;
    },
    //文件上传前的校验
    beforeAvatarUpload(file) {
      const isLt100M = file.size / 1024 / 1024 < 1024;
      const isLt30 = file.name.length < 30;
      if (["video/mp4"].indexOf(file.type) == -1) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt100M) {
        this.$message.error("上传视频大小要小于1GB哦!");
        return false;
      }
      if (!isLt30) {
        this.$message.error("上传视频文件名称长度必须要小于30个文字哦!");
        return false;
      }
    },
    // http-request属性来覆盖默认的上传行为（即action="url"），自定义上传的实现
    //multipartUpload方法查看官方文档[添加链接描述](https://www.alibabacloud.com/help/zh/doc-detail/31995.htm)
    async Upload(option) {
      const file = option.file;
      const that = this;
      const date = dateFormat(new Date(), "yyyyMMdd"); // 当前时间
      const dateTime = dateFormat(new Date(), "yyyyMMddhhmmss"); // 当前时间
      const randomStr = that.randomString(4); //  4位随机字符串
      const extensionName = file.name.substr(file.name.indexOf(".")); // 文件扩展名
      const fileName =
        that.dataObj.dir +
        date +
        "/" +
        dateTime +
        "_" +
        randomStr +
        extensionName;
      // 执行上传
      let dataObj = {
        accessKeyId: that.dataObj.AccessKeyId,
        secure: true,
        accessKeySecret: that.dataObj.AccessKeySecret,
        bucket: that.dataObj.bucket,
        region: that.dataObj.Region,
        stsToken: that.dataObj.SecurityToken,
      };
      try {
        // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        const result = await client(dataObj).put(fileName, file); // Message.success("上传成功");

        return result;
      } catch (e) {
        // Message.error("上传失败请重试");
        return e;
      }
    },
    randomString(num) {
      const chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let res = "";
      for (let i = 0; i < num; i++) {
        var id = Math.ceil(Math.random() * 35);
        res += chars[id];
      }
      return res;
    },
    // 图片上传前的效验
    beforeAvatarUploadImg(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 30;

      if (!isJPG) {
        this.$message.error("请选择正确的图片类型");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过30MB!");
      }
      return isJPG && isLt2M;
    },
    async UploadImg(option) {
      const file = option.file;
      const that = this;
      const date = dateFormat(new Date(), "yyyyMMdd"); // 当前时间
      const dateTime = dateFormat(new Date(), "yyyyMMddhhmmss"); // 当前时间
      const randomStr = that.randomString(4); //  4位随机字符串
      const extensionName = file.name.substr(file.name.indexOf(".")); // 文件扩展名
      const fileName =
        "image/" + date + "/" + dateTime + "_" + randomStr + extensionName;
      // 执行上传
      let dataObj = {
        accessKeyId: that.dataObj.AccessKeyId,
        secure: true,
        accessKeySecret: that.dataObj.AccessKeySecret,
        bucket: that.dataObj.bucket,
        region: that.dataObj.Region,
        stsToken: that.dataObj.SecurityToken,
      };
      try {
        // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        const result = await client(dataObj).put(fileName, file); // Message.success("上传成功");

        return result;
      } catch (e) {
        // Message.error("上传失败请重试");
        return e;
      }
    },
    // 上传图片成功
    handleSuccessImg(res, file, fileList) {
      this.videoForm.cover = res.url;
    },
    // 上传图片失败
    handfileErrImg(res, file, fileList) {
      this.videoForm.cover = "";
    },
    async getOssData() {
      const [err, res] = await this.$http.post("web/User/getSts", {
        id: this.$store.state.user_id,
        token: this.$store.state.login_token,
      });
      if (err) {
        return;
      }
      this.dataObj = res;
    },
    // 提交视频
    Addvideo() {
      this.$refs.videoForm.validate(async (v, i) => {
        if (v) {
          const [err, res] = await this.$http.post("web/video/add", {
            url: this.videoForm.file,
            desc: this.videoForm.title,
            cover: this.videoForm.cover,
            token: this.$store.state.login_token,
            id: this.$store.state.user_id,
          });
          if (err) {
            return;
          }
          this.videoFlag = false;
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
      });
    },
    handfileErr(err, file, fileList) {
      this.videoForm.file = "";
    },
    // 跳转用户详情
    goUserInfo() {
      this.$router.push("/user_info");
    },
  },
};
</script>
<style lang="less">
.bg_img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 360px;
  height: 160px;
  line-height: 160px;
  text-align: center;
}

.avatar {
  width: 360px;
  height: 160px;
  display: block;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #ffffff;
}

body {
  height: 100vh;

  &.el-container {
    height: 100vh;
  }
}

.avatar_box {
  position: relative;

  .isVip {
    position: absolute;
    right: -8px;
    bottom: 12px;
  }
}

.el-container {
  height: 100vh;
}

* {
  padding: 0;
  margin: 0;
}

.el-button+.el-button {
  margin-left: 0px;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

.icon-collapse {
  color: rgba(0, 0, 0, 0.65);
  font-size: 20px;
  cursor: pointer;
  user-select: none;
  height: 100%;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:hover {
    background-color: #e6f7ff;
  }

  &.active {
    color: #1890ff;
  }
}

.el-aside {
  background: #060716;
  color: #fff;
  text-align: center;
  height: 100%;
  overflow: hidden;

  .btm_box {
    width: 160px;
    position: absolute;
    min-height: 100px;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    font-size: 12px;
  }

  .el-menu-item {
    background: #060716;
    color: #fff;
    font-size: 14px;
  }

  .is-active {
    font-weight: bold;
    font-size: 17px;
  }
}

.btn {
  margin: 10px;
}

.login_btn {
  background-color: #fe2c55;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  margin: 0;
  width: 104px;
}

.header-el {
  display: flex;
  justify-content: space-between;
  line-height: 70px;
  background: transparent;
  width: 100%;
  white-space: nowrap;
}

.wrap {
  padding: 0px;
  min-width: 1600px;
}

/* .slide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } */
.el-input-group__append,
.el-input-group__prepend {
  background: #fff;
}

.footer_tag {
  color: #fff;
  text-align: center;

  a {
    margin: 0 20px;
    padding: 10px 0;
  }
}

.el-scrollbar {
  height: 100%;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-image {
  position: relative;
  display: inline-block;
  overflow: unset;
}

.el-image__inner {
  border-radius: 25px;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.footer_box {
  font-size: 12px;
  background: #060716;
  color: #fff;
  text-align: center;
  color: #000;
  background: transparent;
  z-index: 100;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border: none;
  font-weight: bolder;
}

.el-upload__tip {
  margin-top: 0;
}
.el-menu--horizontal>.el-menu-item {
  height: 70px;
  line-height: 70px;
}
</style>
